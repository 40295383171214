import { createContext, useState, useEffect } from "react";

export default function useWindowSizeListener() {
  const [windowWidth, setwindowWidth] = useState();

  useEffect(() => {
    const handleResize = () => setwindowWidth(window.innerWidth);

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowWidth;
}

export const ScrollContext = createContext(null);

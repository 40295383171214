import React from "react";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import "../styles/main.scss";
import "../styles/navigation.scss";
import useWindowSizeListener from "../helpers/useWindowSizeListener";

export const WindowSizeContext = React.createContext();

const MainLayout = (props) => {
  const windowSize = useWindowSizeListener();

  return (
    <div>
      <WindowSizeContext.Provider value={windowSize}>
        <Navigation />
        {React.cloneElement(props.children, { className: "top bottom" })}
        <Footer />
      </WindowSizeContext.Provider>
    </div>
  );
};

export default MainLayout;

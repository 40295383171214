import React from "react";
import "../styles/footer.scss";
import { Link } from "gatsby";
import { gsap } from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import { useLocation } from "@reach/router";

gsap.registerPlugin(ScrollToPlugin);

const Footer = () => {
  const handleScroll = (section) => {
    gsap.to(window, {
      duration: 0.5,
      scrollTo: section,
      offsetY: 50,
    });
  };

  const location = useLocation();

  return (
    <footer className="footer">
      <div className="container">
        {location.pathname !== "/" ? (
          <>
            <Link to="/#o-firmie">O firmie</Link>
            <Link to="/#flot">Flota</Link>
            <Link to="/#kariera">Kariera</Link>
            <Link to="/#kontakt">Kontakt</Link>
          </>
        ) : (
          <>
            <p onClick={() => handleScroll("#o-firmie")}>O firmie</p>
            <p onClick={() => handleScroll("#flota")}>Flota</p>
            <p onClick={() => handleScroll("#kariera")}>Kariera</p>
            <p onClick={() => handleScroll("#kontakt")}>Kontakt</p>
          </>
        )}
      </div>
    </footer>
  );
};

export default Footer;
